import { mapState } from 'vuex'
import { format, addMonths } from 'date-fns'
import arrObjOperation from '@/mixins/arrObjOperation'

export default {
  name: 'userProfileMixin',
  data() {
    return {
      permissionObj: [],
      isRoleFound: false
    }
  },
  created() {
    if (this.userProfile.id==undefined) {
      this.$store.dispatch('authen/logout')
    }
    if (this.userProfile.disabled) {
      this.$router.push('/pages/miscellaneous/not-authorized')
    }
    if (this.userProfile.zChangePW) {
      this.$router.push('/apps/profile')
    }
  },
  mounted() {
    // geolocation:
    // this.getGeolocationInformation(),
    this.$store.dispatch('baseConfig/getBaseConfigArray')    
    // this.$store.dispatch('stripe/getCheckOutSession')
    // this.$store.dispatch('stripe/checkCheckOutSession')
    // this.$store.dispatch('stripe/checkPayment')
    // this.$store.dispatch('stripe/checkSubscription')
  },
  mixin: [ arrObjOperation ],
  watch: {
    // (1) After Login, load SITE, CONFIG, ROLES, users, products,
    isUserProfileLoaded() {
      if (this.isUserProfileLoaded) {
        
        // Get Config Items
        this.$store.dispatch('config/getConfigItems2', this.userProfile.zCusId)

        // Get users, roles and products
        this.$store.dispatch('authen/get_sys_users', this.userProfile.zCusId)
        this.$store.dispatch('authen/get_sys_roles', this.userProfile.zCusId)
        this.$store.dispatch('product/getProducts')
        this.$store.dispatch('stripe/getStripeProducts')

        // Get site
        this.$store.dispatch('site/getSite', this.userProfile.zCusId)

        // Save History
        let authItem = {}
        authItem.userName = this.userProfile.userName
        authItem.email = this.userProfile.email
        authItem.title = this.userProfile.title
        authItem.authType = 'login'
        authItem.at = format(new Date(), "yyyy-MM-dd HH:mm:ss")
        if (this.isMobile()) {
          authItem.connection = 'mobile'
        } else {
          authItem.connection = 'web'
        }
        authItem.zCusId = this.userProfile.zCusId
        this.$store.dispatch('histAuth/clearHistAuth')
        // geolocation
        authItem.geoInfo = this.geoData
        this.$store.dispatch('histAuth/addHistAuthItem', authItem)

        // route to todo
        this.$router.push({ name: 'apps-todo-task' })        
      }
    },

    // (2) SITE - if not exist, create    
    isLoadingSite() {
      if (this.isLoadingSite) {
        if ( JSON.stringify(this.site)==undefined && !this.isNewSiteCreated) {
        // if ( Object.keys(this.site).length===0 && !this.isNewSiteCreated) {
          let siteInfo = {}
          siteInfo.cusId = this.userProfile.zCusId
          siteInfo.plan = 'Free' 
          siteInfo.regUser = this.userProfile.id
          siteInfo.regUserName = this.userProfile.userName
          siteInfo.regUserEmail = this.userProfile.email
          siteInfo.since = format(new Date(), "yyyy-MM-dd HH:mm:ss")
          // valid for 3 months
          siteInfo.till = format(addMonths(new Date(), 3), "yyyy-MM-dd HH:mm:ss")
          siteInfo.till_purchasedStorage = siteInfo.till
          siteInfo.zPartnerCode =  ''
          siteInfo.numOfUsersLimit = '3'
          siteInfo.numOfUsersUsed = '1'
          siteInfo.planStorage = '50MB'
          siteInfo.purchasedStorage = '0'
          siteInfo.storageUsed = '0'
          siteInfo.sub_numTier = '0'
          siteInfo.sub_numStorage = '0'
          siteInfo.sub_isResolved = true
          siteInfo.createdAt = format(new Date(), "yyyy-MM-dd HH:mm:ss")
          siteInfo.updatedAt = format(new Date(), "yyyy-MM-dd HH:mm:ss")
          siteInfo.promoCode = this.userProfile.promoCode
          siteInfo.promoCodeChecked = false
          this.$store.dispatch('site/addSite', siteInfo)
          this.$store.commit('site/SET_NEWSITECREATED', true)
          this.$store.dispatch('site/setIsLoadingSite', false)
        }  
      }
    },

    // (3) CONFIG - if not exist, create
    isLoadingConfig() {
      if ( this.isLoadingConfig ) {
        if ( this.configItems2.config==undefined && !this.isNewConfigCreated ) {
          let basicConfigItem = {}
          basicConfigItem.config = this.baseConfigArray[0].config
          basicConfigItem.customFields = this.baseConfigArray[0].customFields
          basicConfigItem.zCusId = this.userProfile.zCusId
          this.$store.dispatch('config/addConfigItem', basicConfigItem)
          this.$store.commit('config/SET_NEWCONFIGCREATED', true)
          this.$store.dispatch('config/setIsLoadingConfig', false)
        }
      }
    },

    // (4) ROLES: - if not exist, create + Append to userProfile
    // (a) if not exist, create
    isLoadingRoles() {
      if ( this.isLoadingRoles ) {
        if ( !this.sys_roles.length && !this.isNewRolesCreated ) {
          let basicRole = {}
          basicRole.caption = 'Public'
          basicRole.roleName = 'Basic'
          basicRole.description = 'Default role for Basic Plan Users'
          basicRole.members = []
          basicRole.members.push(this.userProfile.id)
          basicRole.permissions = this.baseConfigArray[0].permissions
          basicRole.zCusId = this.userProfile.zCusId
          this.$store.dispatch('authen/addUserRole', basicRole)
          this.$store.commit('authen/SET_ISNEWROLESCREATED', true)
          this.$store.dispatch('authen/setIsLoadingRoles', false)
        } else {
          // if no need to create, set flag to true to proceed appending roles to userProfile
          if ( this.sys_roles.length ) {
            this.$store.commit('authen/SET_ISNEWROLESCREATED', true)
          }
        }
      }
    },
    
    // (b) if created, append to userProfile, load public id, load data
    isNewRolesCreated() {
      if ( this.isNewRolesCreated ) {
        if ( this.sys_roles.length && this.userProfile!==undefined ) {          
          let userProfileRoles = []
          let userProfilePermissions = []
          let userProfileTemp = {}

          // Prepare userProfileRoles & userProfilePermissions to be put into userProfile
          this.sys_roles.forEach(sys_role => {
            if (sys_role.members.includes(this.userProfile.id)) {
              this.isRoleFound = true
              userProfileRoles.push(sys_role.id)
              userProfilePermissions.push(sys_role.permissions)
            }
          })

          // Put roles & permissions into userProfile
          if ( this.isRoleFound ) {
            let userProfilePermission = this.manyToOnePermission(userProfilePermissions) // consolidated permissions      
            userProfileTemp = this.userProfile
            this.$set(userProfileTemp, 'roles', userProfileRoles)
            this.$set(userProfileTemp, 'permissions', userProfilePermission)        
            this.$store.commit('authen/SET_USERPROFILE', userProfileTemp)
          }
          
          // Load Public Id if it wasn't)
          if ( this.publicId == '' ) {
            this.$store.dispatch(
              'authen/setPublicId',
              this.sys_roles.find(r => 
                r.caption=='Public' && r.zCusId==this.userProfile.zCusId
              ).id
            )
          }

          // Load data
          // task, different from note, blog and doc => getTasks and getMoreTasks
          this.$store.dispatch('task/getTasks', this.userProfile)
          this.$store.dispatch('note/getNotes', this.userProfile)
          this.$store.dispatch('comment/getComments', this.userProfile.zCusId)
          this.$store.dispatch('channel/getChannels', this.userProfile.zCusId)
          this.$store.dispatch('response/getResponses', this.userProfile.zCusId)
          this.$store.dispatch('history/getHistoryItems', this.userProfile)
          // this.$store.dispatch('site/setLoadingDataStage', 'mine')
          // console.log('isLoadingSite: ' + this.isLoadingSite)
          // console.log('roles.length: ' + this.userProfile.roles.length)
          // console.log('site plan: ' + this.site.plan)
          // console.log('isLoadingSite: ' + this.isLoadingSite)
        }
      }
    },

    // (5) Follow 4b: load my data
    loadingDataStage() {
      // load data
      if ( this.userProfile.roles.length > 1 ) {
        this.$store.dispatch('task/getMoreTasks', this.userProfile)
        this.$store.dispatch('note/getMoreNotes', this.userProfile)
        this.$store.dispatch('doc/getDocs', this.userProfile)
        this.$store.dispatch('blog/getBlogs', this.userProfile)

        // load delta data
        this.$store.dispatch('blog/getDeltaBlogs', this.userProfile)
        this.$store.dispatch('note/getDeltaNotes', this.userProfile) 
        this.$store.dispatch('doc/getDeltaDocs', this.userProfile)
        this.$store.dispatch('task/getDeltaTasks', this.userProfile)
      }

      // switch loading stages
      switch ( this.loadingDataStage ) {
        case 'mine':
          if ( this.site.plan !== 'Plus' ) {
            this.$store.dispatch('site/setLoadingDataStage', 'public')
          } else {
            this.$store.dispatch('site/setLoadingDataStage', 'roles')
          }
          break
        case 'roles':
          this.$store.dispatch('site/setLoadingDataStage', 'public')
          break
        case 'public':
          this.$store.dispatch('site/setLoadingDataStage', 'done')
          break
        
        // Load Delta data
        case 'mine_delta':
          // console.log('--- Loading mine_delta -----')
          if ( this.site.plan !== 'Plus' ) {
            this.$store.dispatch('site/setLoadingDataStage', 'public_delta')
          } else {
            this.$store.dispatch('site/setLoadingDataStage', 'roles_delta')
          }
          break
        case 'roles_delta':
          // console.log('--- Loading roles_delta -----')
          this.$store.dispatch('site/setLoadingDataStage', 'public_delta')
          break
        case 'public_delta':
          // console.log('--- Loading public_delta -----')
          this.$store.dispatch('site/setLoadingDataStage', 'done')
          break
        
        // Done
        case 'done':
          // console.log('Loading Data Finished.')
      }
    },

    site() {
      if ( this.site !== undefined ) {
        this.$store.dispatch('site/setLoadingDataStage', 'mine')
      }
    },
  },
  methods: {async getGeolocationInformation() {
    const API_KEY = '90c1a86561c74a52aefb7f25b808173e'
    const API_URL = 'https://ipgeolocation.abstractapi.com/v1/?api_key=' + API_KEY
    // const apiResponse = await fetch(API_URL)   ...................................................
    // this.$store.dispatch('authen/setGeoData', await apiResponse.json())
  },    
  isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
  },
  // permissions => permissionObj
  // permissions: permission set; permission: task/goal, comment, etc.
  manyToOnePermission(permissions) {
    // let permissionObj = []
    // let XLoop = 0
    // let YLoop = 0
    let exit1stLoop = false
    // let exitLoop = false

    // if there is only 1 role (i.e. 1 permission set), return it straight
    // if ( permissions.length == 1 ) {
    //   permissionObj = permissions[0]  // check !!!!!!!!!!!!!!!!!!!!!!
    // } else { 
      permissions.forEach(permission => {
        permission.forEach(permissionItem => {
          if (!exit1stLoop) {
          // if (!this.permissionObj.includes(permissionItem.module)) {
            this.permissionObj.push(permissionItem)
          } 
          else {

            let permissionObj1 = this.permissionObj.find(pObj =>pObj.module === permissionItem.module)

            // if (this.permissionObj.module == permissionItem.module && !exitLoop) {
            // if (!exitLoop) {
              if (permissionObj1.access==false && permissionItem.access==false) {
                permissionObj1.access = false
              } else {
                if (!permissionItem.module.startsWith('custom')) {
                  permissionObj1.access = true
                }
              }
              if (permissionObj1.read==false && permissionItem.read==false) {
                permissionObj1.read = false
              } else {
                permissionObj1.read = true
              }
              if (permissionObj1.write==false && permissionItem.write==false) {
                permissionObj1.write = false
              } else {
                permissionObj1.write = true
              }
              if (permissionObj1.delete==false && permissionItem.delete==false) {
                permissionObj1.delete = false
              } else {
                permissionObj1.delete = true
              }

              // update permissionObj
              this.permissionObj.forEach(permissionObjItem => {
                if ( permissionObjItem.module == permissionObj1.module ) {
                  permissionObjItem.access = permissionObj1.access
                  permissionObjItem.read = permissionObj1.read
                  permissionObjItem.write = permissionObj1.write
                  permissionObjItem.delete = permissionObj1.delete
                }
              })
              this.permissionObj.update


              // exitLoop = true
            // }
          }
          // XLoop += 1
        })
        // YLoop += 1
        // XLoop = 0
        
        exit1stLoop = true
      })

    return this.permissionObj
  },
    // Load RPC - Role / Permission / ConfigItems
    // loadRPC() {
    //   let userProfileRoles = []
    //   let userProfilePermissions = []
    //   let userProfileTemp = {}
    //   // let basicRole = {}
    //   // let basicConfigItem = {}

    //   // Prepare userProfileRoles & userProfilePermissions to be put into userProfile
    //   this.sys_roles.forEach(sys_role => {
    //     if (sys_role.members.includes(this.userProfile.id)) {
    //       this.$store.commit('authen/SET_ISROLEFOUND', true)
    //       userProfileRoles.push(sys_role.id)
    //       userProfilePermissions.push(sys_role.permissions)
    //     }
    //   })

    //   // if (this.isRoleFound & !this.isNewConfigCreated) {

    //     // Role is Found
    //     // Put roles & permissions into userProfile
    //     let userProfilePermission = this.manyToOnePermission(userProfilePermissions) // consolidated permissions      
    //     userProfileTemp = this.userProfile
    //     this.$set(userProfileTemp, 'roles', userProfileRoles)
    //     this.$set(userProfileTemp, 'permissions', userProfilePermission)        
    //     this.$store.commit('authen/SET_USERPROFILE', userProfileTemp)

    //   // } 
    //   // else {
    //   //   // Role is NOT Found
    //   //   if (!this.isNewConfigCreated) {
    //   //     // Create Roles
    //   //     basicRole.caption = 'Public'
    //   //     basicRole.roleName = 'Basic'
    //   //     basicRole.description = 'Default role for Basic Plan Users'
    //   //     basicRole.members = []
    //   //     basicRole.members.push(this.userProfile.id)
    //   //     basicRole.permissions = this.baseConfigArray[0].permissions
    //   //     basicRole.zCusId = this.userProfile.zCusId
    //   //     this.$store.dispatch('authen/addUserRole', basicRole)
    //   //     this.$store.commit('authen/SET_USERPROFILE', userProfileTemp)

    //   //     // Put the created roles & permissions to userProfile
    //   //     userProfileTemp = this.userProfile
    //   //     this.$set(userProfileTemp, 'roles', basicRole)
    //   //     this.$set(userProfileTemp, 'permissions', basicRole.permissions)
    //   //     this.$store.commit('authen/SET_USERPROFILE', userProfileTemp)

    //   //     // Create Config Items
    //   //     // basicConfigItem.config = this.baseConfigArray[0].config
    //   //     // basicConfigItem.customFields = this.baseConfigArray[0].customFields
    //   //     // basicConfigItem.zCusId = this.userProfile.zCusId
    //   //     // this.$store.dispatch('config/addConfigItem', basicConfigItem)
    //   //   }
    //   // }

    //   // Load Public Id if it wasn't)
    //   if ( this.publicId == '' ) {
    //     this.$store.dispatch(
    //       'authen/setPublicId',
    //       this.sys_roles.find(r => 
    //         r.caption=='Public' && r.zCusId==this.userProfile.zCusId
    //       ).id
    //     )
    //   }
      
    //   // Declare the RPC has been loaded
    //   // this.$store.commit('authen/SET_ISRPCLOADED', true)      
    // },
    // geolocation
  },
  computed: {
    ...mapState({
      userProfile: state => state.authen.userProfile,
    }),
    ...mapState({
      forceReload: state => state.authen.forceReload,
    }),
    ...mapState({
      publicId: state => state.authen.publicId,
    }),
    // ...mapState({
    //   isRPCLoaded: state => state.authen.isRPCLoaded,
    // }),
    ...mapState({
      isNewSiteCreated: state => state.site.isNewSiteCreated,
    }),
    ...mapState({
      isUserProfileLoaded: state => state.authen.isUserProfileLoaded,
    }),
    // ...mapState({
    //   isUsersLoaded: state => state.authen.isUsersLoaded,
    // }),
    ...mapState({
      isLoadingRoles: state => state.authen.isLoadingRoles,
    }),
    ...mapState({
      isEmailVerified: state => state.authen.isEmailVerified,
    }),
    ...mapState({
      sys_users: state => state.authen.sys_users,
    }),
    ...mapState({
      sys_roles: state => state.authen.sys_roles,
    }),
    // geolocation:
    ...mapState({
      geoData: state => state.authen.geoData,
    }),
    ...mapState({
      baseConfigArray: state => state.baseConfig.baseConfigArray,
    }),
    ...mapState({
      isNewConfigCreated: state => state.config.isNewConfigCreated,
    }),
    ...mapState({
      isNewRolesCreated: state => state.authen.isNewRolesCreated,
    }),
    ...mapState({
      configItems2: state => state.config.configItems2,
    }),
    ...mapState({
      isLoadingConfig: state => state.config.isLoadingConfig,
    }),
    ...mapState({
      isLoadingSite: state => state.site.isLoadingSite,
    }),
    // ...mapState({
    //   isRoleFound: state => state.authen.isRoleFound,
    // }),
    ...mapState({
      site: state => state.site.site,
    }),

    // data
    ...mapState({
      loadingDataStage: state => state.site.loadingDataStage,
    }),
    ...mapState({
      notes: state => state.note.notes,
    }),
  },
}
